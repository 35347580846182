/* eslint-disable */
import * as React from 'react';
import { __, __r } from 'react-i18n/lib';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { rem, rgba } from 'polished';
import {
  FlexCol,
  FlexRow,
  FlexRowCenter,
  FlexRowCenterVertical,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
  ZlataPriadkaCategoryHeader as CategoryHeader,
  ZlataPriadkaPagination as Pagination,
  ZlataPriadkaCategoryFilter as CategoryFilter,
  ZlataPriadkaCategoryFilterDesktop as CategoryFilterDesktop,
  ZlataPriadkaFilterType as FilterType,
  ZlataPriadkaMobileCategoryFilterButton as MobileCategoryFilterButtons,
  ZlataPriadkaMobileCategoryFilterMenu as MobileCategoryFilterMenu,
  ZlataPriadkaLinksFilter as LinksFilter,
  ZlataPriadkaActiveFilters as ActiveFilters,
  ZlataPriadkaCategoryTopFilters as CategoryTopFilters,
  ZlataPriadkaCategoryFilterCheckboxes as CategoryFilterCheckboxes,
  ZlataPriadkaProductList as ProductList,
  ZlataPriadkaNoItemsWrapper as NoItemsWrapper,
  ZlataPriadkaMobileNavMenu as MobileNavMenu,
  ZlataPriadkaCategoryCard as CategoryCard,
  ZlataPriadkaCategoryCardType as CategoryCardType,
} from 'eshop-defaults';
import { prop, getCompanyInfoData } from '../../utilities';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { getCategoryMetaTags } from '../../utilities/metatags';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { withRouter, WithRouterProps } from 'react-router';
import {
  urlAttribsToObject,
  attribsObjectToUrl,
  fetchCategory,
} from '../../containers/Category/categorySlice';
import { resolveCategoryShortUrl } from 'eshop-defaults/lib/utilities/selectors';
import {
  getCategoryIdsFromProduct,
  getCategoryImagePath,
} from '../../utilities/category';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import { css } from 'styled-components';
import {
  IconType,
  SvgIcon,
} from 'eshop-defaults/lib/components/ZlataPriadka/General/SvgIcon';
import {
  addItemToFavourite,
  loadFavouriteProducts,
  removeItemFromFavourite,
} from '../../containers/Home/actions';
import { push } from 'react-router-redux';

import { BreadcrumbsMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { getCategoryBreadcrumbMicrodata } from '../../utilities/microdata';
import { resolveLangByHostname } from '../../configureTrans';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';

let fetchingMore = false;
const INITIALLY_OPEN_ATTRIBS = ['Farba'];
export interface Props {
  dispatch: any;
  isFetching: boolean;
  // products: ThenArg<typeof API.loadElasticCategoryProducts>;
  products: any;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  catName: string;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  childrenCategories: any[];
  cart: ThenArg<typeof API.getCart> | null;
  categories: ThenArg<typeof API.loadTree>;
  categoryId?: any;
  homeCompanyInfo: any[];
  favouriteProducts: any;
}

function Category({
  isFetching,
  products,
  data,
  dispatch,
  user,
  catName,
  filterData,
  location,
  router,
  childrenCategories,
  cart,
  categories,
  categoryId,
  homeCompanyInfo,
  favouriteProducts,
}: Props & WithRouterProps) {
  const catUrl = prop(location, 'pathname');
  const query = prop(location, 'search');
  const urlAttribs = prop(location, 'query');
  const urlAttribsObj = urlAttribsToObject(urlAttribs);
  const currentCategory = prop(data, 'category_id', catName);
  const currentCategoryImage = prop(data, 'image', null);
  const parentCategories = prop(data, 'parent_categories', []);
  const parentCategoryId = prop(data, 'category_parent', null);

  const topLevelParentCategory =
    parentCategories && parentCategories.length > 0
      ? parentCategories[0].image
      : !parentCategoryId && currentCategoryImage
      ? currentCategoryImage
      : null;

  const placeholderImgSrc = topLevelParentCategory
    ? getCategoryImagePath(
        topLevelParentCategory,
        {
          width: 56,
          height: 56,
        },
        false,
        false,
        false,
      )
    : '/images/zlatapriadka/placeholder.png';

  const [yPosition, setYPosition] = React.useState(0);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (window && yPosition > 0 && products && !isFetching) {
        window.scrollTo({ behavior: 'smooth', top: yPosition });
        setYPosition(0);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, 400);
  }, [window, yPosition, products, isFetching]);

  const [isCategoryFilterVisible, setIsCategoryFilterVisible] = React.useState(
    false,
  );
  const [isFilterMenuVisible, setIsFilterMenuVisible] = React.useState(false);
  const categoryName = catName ? catName : prop(data, 'category_name');
  const categoryContent = prop(data, 'content', []);
  const categoryDesc =
    categoryContent.length > 0
      ? prop(categoryContent[0], 'json_content.body', '')
      : '';

  const productCategoryIds = getCategoryIdsFromProduct(data);
  productCategoryIds.push(prop(data, 'category_number', ''));

  childrenCategories = childrenCategories.filter(c => c.counts && c.counts > 0);

  const categoryLinks = childrenCategories.map(c => ({
    name: c.category_name,
    url: resolveCategoryShortUrl(c.category_id, c.url),
    image: c.image
      ? getCategoryImagePath(
          c.image,
          {
            width: 56,
            height: 56,
          },
          false,
          false,
          false,
        )
      : placeholderImgSrc,
  }));

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.CATEGORY, data));
  }, [data, dispatch]);

  const toggleCategoryFilterMenu = () => {
    // document.body.style.overflow = isCategoryFilterVisible
    //   ? 'visible'
    //   : 'hidden';
    setIsCategoryFilterVisible(!isCategoryFilterVisible);
  };

  const toggleFilterMenu = () => {
    // document.body.style.overflow = isFilterMenuVisible ? 'visible' : 'hidden';
    setIsFilterMenuVisible(!isFilterMenuVisible);
  };

  const handleFilterChange = (
    type: FilterType,
    newData:
      | { min: number; max: number; attribId?: string }
      | {
          attribId: string;
          valueId: string;
          newValue: boolean;
          sameIds?: string[];
        },
  ) => {
    setYPosition(window.pageYOffset);
    switch (type) {
      case FilterType.RANGE: {
        const attribId = prop(newData, 'attribId');
        if (attribId) {
          const currentRangeAttribFilters = prop(urlAttribsObj, 'rangeAttribs');
          const min = parseInt(prop(newData, 'min').toFixed(0));
          const max = parseInt(prop(newData, 'max').toFixed(0));
          const { attribs } = filterData;
          const attribDataMin = prop(attribs[attribId], 'attrib_data.min');
          const attribDataMax = prop(attribs[attribId], 'attrib_data.max');

          let rangeAttribsObj = currentRangeAttribFilters
            ? currentRangeAttribFilters
            : {};

          if (
            Math.floor(attribDataMin) !== min ||
            Math.ceil(attribDataMax) !== max
          ) {
            rangeAttribsObj[attribId] = {
              min: parseInt(prop(newData, 'min').toFixed(0)),
              max: parseInt(prop(newData, 'max').toFixed(0)),
            };
          } else {
            delete rangeAttribsObj[attribId];
          }
          router.push(
            attribsObjectToUrl(catUrl, {
              ...urlAttribsObj,
              rangeAttribs: rangeAttribsObj,
            }),
          );
        } else {
          urlAttribsObj.min = parseInt(prop(newData, 'min').toFixed(0));
          urlAttribsObj.max = parseInt(prop(newData, 'max').toFixed(0));
          router.push(attribsObjectToUrl(catUrl, urlAttribsObj));
        }

        break;
      }

      case FilterType.OPTIONS: {
        const attribId = prop(newData, 'attribId');
        const valueId = prop(newData, 'valueId');
        const newValue = prop(newData, 'newValue');
        const currentAttribFilters = prop(urlAttribsObj, 'attribs');

        let updatedAttribsObj = currentAttribFilters;
        let values = updatedAttribsObj
          ? prop(currentAttribFilters[attribId], 'values')
          : null;
        if (values) {
          if (newValue) {
            values.push(valueId);
          } else {
            values = values.filter(v => valueId.toString() !== v);
          }
          if (values.length === 0) {
            updatedAttribsObj[attribId].values = null;
            delete updatedAttribsObj[attribId];
          } else {
            updatedAttribsObj[attribId].values = values;
          }
        } else {
          updatedAttribsObj = {
            ...updatedAttribsObj,
            [attribId]: { values: [valueId] },
          };
        }

        router.push(
          attribsObjectToUrl(catUrl, {
            ...urlAttribsObj,
            attribs: updatedAttribsObj,
          }),
        );

        break;
      }

      default:
        break;
    }
  };

  const handleSortChange = e => {
    const value = e.target.value;
    const [sort, sortDir] = value.split('_');
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        sort,
        sortDir: sortDir ? sortDir : null,
      }),
    );
  };

  const handleCheckboxChange = ({ name, value }) => {
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        otherAttribs: {
          ...urlAttribsObj.otherAttribs,
          [name]: value ? 1 : 0,
        },
      }),
    );
  };

  const toggleFavourite = async (productId, newVal = null) => {
    if (user) {
      if (!newVal) {
        await dispatch(removeItemFromFavourite(user.id!, productId));
      } else {
        await dispatch(addItemToFavourite(user.id!, productId));
      }

      dispatch(loadFavouriteProducts(user.id?.toString() as string, true));
    } else {
      await dispatch(push(__r('routes:moj-ucet', '/moj-ucet')));
    }
  };

  const allowedCustomAttribs = [
    'Farba',
    'Materiál',
    'Obdobie',
    'Výrobca',
    'Zloženie',
    'Motív',
  ];

  const { limit, offset, total, products: productsArray } = products || {};
  const { minPrice, maxPrice, attribs } = filterData;

  const renderCustomAttribs = () => {
    return (
      <>
        {attribs &&
          Object.keys(attribs).map(key => {
            const attrib = attribs[key];
            if (
              !allowedCustomAttribs.includes(
                prop(attrib, 'attrib_data.attrib_name'),
              )
            ) {
              return;
            }

            const attribType = prop(attrib, 'attrib_data.attrib_type');
            const attribUnit = prop(attrib, 'attrib_data.attrib_unit');
            const valuesObj = attribs[key].values;
            const filteredAttribs = {};
            const attribKeys: any[] = [];
            Object.keys(valuesObj).map(key => {
              const value = prop(valuesObj[key], 'info.attrib_value');
              if (!value || value === '' || value === '-') {
              } else {
                filteredAttribs[key] = valuesObj[key];
              }

              const attribId = prop(valuesObj[key], 'info.attrib_id');
              if (!attribKeys.includes(attribId)) {
                attribKeys.push(attribId);
              }
            });

            const hasValues = Object.keys(filteredAttribs).length > 0;
            if (hasValues) {
              const showFilter = Object.keys(filteredAttribs).findIndex(
                valueKey => filteredAttribs[valueKey].doc_count > 0,
              );
              const activeValues: any[] = [];
              attribKeys.map(attrId => {
                activeValues.push(
                  ...prop(
                    prop(urlAttribsObj, 'attribs', {})[attrId],
                    'values',
                    [],
                  ),
                );
              });
              const rangeActiveValues = {};
              attribKeys.map(attrId => {
                const value = prop(urlAttribsObj, 'rangeAttribs', {})[attrId];
                if (value) {
                  rangeActiveValues[attrId] = value;
                }
              });
              const rangeActive =
                rangeActiveValues[prop(attrib, 'attrib_data.attrib_id')];
              const hasActive = rangeActive || activeValues.length > 0;

              const attribName = prop(
                attrib,
                'attrib_data.attrib_name',
                `Attrib ${key}`,
              );

              if (showFilter !== -1 || hasActive) {
                if (attribType === 'rozsah') {
                  return (
                    <CategoryFilter
                      key={key}
                      name={attribName}
                      type={FilterType.RANGE}
                      filterData={{
                        activeMin: prop(
                          rangeActive,
                          'min',
                          prop(attrib, 'attrib_data.min', 0),
                        ),
                        activeMax: prop(
                          rangeActive,
                          'max',
                          prop(attrib, 'attrib_data.max', 0),
                        ),
                        min: +prop(attrib, 'attrib_data.min'),
                        max: +prop(attrib, 'attrib_data.max'),
                        attribId: prop(attrib, 'attrib_data.attrib_id'),
                      }}
                      handleFilterChange={handleFilterChange}
                      activeValues={rangeActiveValues}
                      initialIsOpen={rangeActive}
                      unit={attribUnit}
                    />
                  );
                }

                return (
                  <CategoryFilterDesktop
                    key={key}
                    name={attribName}
                    type={FilterType.OPTIONS}
                    filterData={{
                      values: prop(attrib, 'values'),
                      attribId: key,
                    }}
                    handleFilterChange={handleFilterChange}
                    activeValues={activeValues}
                    initialIsOpen={
                      hasActive || INITIALLY_OPEN_ATTRIBS.includes(attribName)
                    }
                  />
                );
              }
            }
            return null;
          })}
      </>
    );
  };

  const renderCategoriesFilter = toggleCategoryFilterMenu => {
    return (
      <MobileCategoryFilter>
        {categoryLinks && categoryLinks.length > 0 ? (
          <LinksFilter
            isOpen={true}
            isMobile={true}
            filterData={{ links: categoryLinks }}
            closeMenu={toggleCategoryFilterMenu}
          />
        ) : (
          <NoItemsWrapper
            style={{ marginLeft: '0' }}
            height={100}
            text={__('Nenašli sa žiadne ďalšie podkategórie')}
          />
        )}
      </MobileCategoryFilter>
    );
  };

  const renderFilterCheckboxes = () => {
    return (
      <CategoryFilterCheckboxes
        filters={prop(urlAttribsObj, 'otherAttribs')}
        handleCheckboxChange={handleCheckboxChange}
        isB2B={user && user.b2b}
        label={'Len skladom'}
      />
    );
  };

  const renderOtherFilters = () => {
    return (
      <MobileCategoryFilter>
        <MobileCheckboxesWrapper>
          <CategoryFilter
            name={''}
            type={FilterType.RANGE}
            initialIsOpen={true}
            isMobile={true}
            filterData={{
              min: minPrice,
              max: maxPrice,
              activeMin: prop(urlAttribs, 'min'),
              activeMax: prop(urlAttribs, 'max'),
            }}
            handleFilterChange={handleFilterChange}
            unit={'€'}
          />
          {renderFilterCheckboxes()}
        </MobileCheckboxesWrapper>
        {renderCustomAttribs()}
      </MobileCategoryFilter>
    );
  };

  const renderRightSide = () => {
    if (!fetchingMore && (isFetching || !attribs)) {
      return <LoaderWrapper height={200} />;
    }
    const sort = prop(urlAttribs, 'sort');
    const sortDir = prop(urlAttribs, 'sortDir');

    const sortValue = sort && sortDir ? `${sort}_${sortDir}` : 'none';
    const activeAttribs = JSON.parse(
      JSON.stringify(prop(urlAttribsObj, 'attribs', {})),
    );
    Object.keys(activeAttribs).map(key => {
      const activeValues = activeAttribs[key].values;
      // const valuesObj = attribs[key]
      //   ? arrayToObject(attribs[key].values, 'attrib_value')
      //   : {};
      const valuesObj = attribs[key].values;
      const newValues: any[] = [];
      activeValues.map(value => {
        newValues.push({
          valueId: value,
          name: prop(valuesObj, `${value}.info.attrib_value`, value),
          attribName: prop(attribs, `${key}.attrib_data.attrib_name`),
        });
      });
      activeAttribs[key].values = newValues;
    });

    const renderSubcategories = (): JSX.Element => {
      return (
        <CategoryCardsContainer
          offset={childrenCategories.length === 0 ? 0 : `16px`}
        >
          {childrenCategories.map((c, index) => (
            <CategoryCard
              key={index}
              type={CategoryCardType.CLASSIC}
              name={c.category_name}
              url={resolveCategoryShortUrl(c.category_id, c.url)}
              img={
                c.image
                  ? getCategoryImagePath(
                      c.image,
                      {
                        width: 56,
                        height: 56,
                      },
                      false,
                      false,
                      false,
                    )
                  : placeholderImgSrc
              }
            />
          ))}
        </CategoryCardsContainer>
      );
    };

    const renderMoreItemsButton = () => {
      if (limit + offset >= total) return null;
      if (total <= limit) return null;
      return (
        <StyledButton onClick={showMoreItem}>
          {__('Načítať viac produktov')}
        </StyledButton>
      );
    };

    async function showMoreItem() {
      fetchingMore = true;
      const cat = await Promise.all([
        dispatch(
          fetchCategory(
            currentCategory,
            urlAttribsObj,
            limit,
            offset + 12,
            true,
          ),
        ),
      ]);
    }

    return (
      <RightWrapper>
        <CategoryHeader title={categoryName} desc={categoryDesc} />
        {isFetching && !fetchingMore ? (
          <LoaderWrapper height={500} />
        ) : !productsArray ? null : (
          <>
            {childrenCategories &&
              childrenCategories.length > 0 &&
              renderSubcategories()}
            <SortWrapper isOffsetTop={childrenCategories.length > 0}>
              <CategoryTopFilters
                handleChange={handleSortChange}
                sortValue={sortValue}
              >
                <CategoryFilter
                  name={''}
                  type={FilterType.RANGE}
                  initialIsOpen={true}
                  isMobile={false}
                  filterData={{
                    min: minPrice,
                    max: maxPrice,
                    activeMin: prop(urlAttribs, 'min'),
                    activeMax: prop(urlAttribs, 'max'),
                  }}
                  handleFilterChange={handleFilterChange}
                  unit={'€'}
                />
                <DesktopCheckboxesWrapper>
                  {renderFilterCheckboxes()}
                </DesktopCheckboxesWrapper>
              </CategoryTopFilters>
            </SortWrapper>
            {activeAttribs && Object.keys(activeAttribs).length > 0 && (
              <DesktopActiveFiltersWrapper>
                <ActiveFilters
                  resetUrl={catUrl}
                  activeAttribs={activeAttribs}
                  onClickAttrib={handleFilterChange}
                />
              </DesktopActiveFiltersWrapper>
            )}
            <MobileFiltersWrapper>
              <MobileCategoryFilterButtons
                onClick={toggleCategoryFilterMenu}
                text={__('Ponuka tovaru')}
                iconType={'CATEGORY'}
              />
              <MobileCategoryFilterButtons
                onClick={toggleFilterMenu}
                text={__('Filter')}
                iconType={'FILTER'}
              />
            </MobileFiltersWrapper>
            {activeAttribs && Object.keys(activeAttribs).length > 0 && (
              <MobileActiveFiltersWrapper>
                <ActiveFilters
                  resetUrl={catUrl}
                  activeAttribs={activeAttribs}
                  onClickAttrib={handleFilterChange}
                />
              </MobileActiveFiltersWrapper>
            )}
            <ListWrapper>
              <ProductList
                isFetching={isFetching && !fetchingMore}
                products={productsArray}
                visible={true}
                productsInRow={3}
                isElasticCategory={true}
                toggleFavourite={toggleFavourite}
                favouriteProducts={favouriteProducts}
              />
            </ListWrapper>
            {renderMoreItemsButton()}
            <Pagination
              query={`${catUrl}${`${query}` ? query : '?'}`}
              totalItems={total}
              limit={limit}
              offset={offset}
            />
          </>
        )}
      </RightWrapper>
    );
  };

  const renderCompanyInfo = () => {
    let { output } = getCompanyInfoData(homeCompanyInfo);

    return (
      <CompanyInfoWrapper>
        <CompanyInfoElement>
          <StyledSvgIcon
            width={26}
            height={26}
            viewBox={'0 0 52 52'}
            marginRight={16}
            icon={IconType.ball}
          />
          {output[0]}
        </CompanyInfoElement>
        <CompanyInfoElement>
          <StyledSvgIcon
            width={26}
            height={26}
            viewBox={'0 0 52 52'}
            marginRight={16}
            icon={IconType.calendar}
          />
          {output[1]}
        </CompanyInfoElement>
        <CompanyInfoElement>
          <StyledSvgIcon
            width={26}
            height={26}
            viewBox={'0 0 52 52'}
            marginRight={16}
            icon={IconType.heart}
          />
          {output[2]}
        </CompanyInfoElement>
      </CompanyInfoWrapper>
    );
  };

  const renderLeftSide = () => {
    return (
      <LeftWrapper>
        <CategoryNameBagde>{categoryName}</CategoryNameBagde>
        {renderCustomAttribs()}
        {renderCompanyInfo()}
      </LeftWrapper>
    );
  };

  return (
    <>
      <BreadcrumbsMicrodata
        items={getCategoryBreadcrumbMicrodata(
          data,
          isSSR()
            ? 'sk'
            : resolveLangByHostname(
                typeof window !== 'undefined' ? window.location.hostname : '',
              ),
        )}
      />
      <Wrapper className="container container--wide">
        <MetaTags tags={getCategoryMetaTags(data)} />
        <BreadCrumb />
        <ContentWrapper>
          {renderLeftSide()}
          {renderRightSide()}
        </ContentWrapper>
      </Wrapper>
      {isCategoryFilterVisible && (
        <CategoriesFilterWrapper>
          <MobileNavMenu closeMenu={toggleCategoryFilterMenu}>
            <MobileCategoryFilterMenu
              title={__('Ponuka tovaru')}
              handleClose={toggleCategoryFilterMenu}
            >
              {renderCategoriesFilter(toggleCategoryFilterMenu)}
            </MobileCategoryFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </CategoriesFilterWrapper>
      )}
      {isFilterMenuVisible && (
        <CategoriesFilterWrapper>
          <MobileNavMenu closeMenu={toggleFilterMenu}>
            <MobileCategoryFilterMenu
              title={__('Filter')}
              handleClose={toggleFilterMenu}
            >
              {renderOtherFilters()}
            </MobileCategoryFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </CategoriesFilterWrapper>
      )}
    </>
  );
}

const CategoriesFilterWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediab.l925`
    display: block;
  `}
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  margin-bottom: ${rem(56)} !important;

  /* margin-top: ${rem(32)}; */
  padding-top: ${rem(24)};
  padding-left: ${rem(24)};
  padding-right: ${rem(24)};
  flex-direction: column;

  ${({ theme }) => theme.mediab.l1050`
      padding: ${rem(16)} ${rem(16)} 0;
  `}
`;

const ContentWrapper = styled(FlexRow)``;

const MobileCategoryFilter = styled(FlexCol)`
  width: 100%;
  padding: 0;
`;

const RightWrapper = styled(FlexCol)`
  /* overflow: hidden; */
  /* width: 76%; */
  /* max-width: ${({ theme }) => `${theme.grid.container.huge}px`}; */
  /* max-width: calc(100% - 376px); */
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
    max-width: 100%;
    width: 100%;
    margin-top: ${rem(24)};
  `}
`;

const LeftWrapper = styled.div`
  flex: 0 0 ${rem(361)};
  padding-right: ${rem(33)};
  padding-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l1400`
    padding-right: ${rem(24)};
  `}

  ${({ theme }) => theme.mediab.l925`
     display: none;
  `}
`;

const SortWrapper = styled(FlexRowCenter)<{ isOffsetTop: boolean }>`
  margin-top: ${({ isOffsetTop }) => (isOffsetTop ? `${rem(40)}` : 0)};
  margin-bottom: ${rem(32)};
  padding: ${rem(24)};
  justify-content: space-between;
  background: ${({ theme }) => rgba(theme.colors.primary, 0.12)};
  border-radius: ${rem(8)};

  ${({ theme }) => theme.mediab.l925`
    margin-top: ${rem(0)};
    padding-bottom: ${rem(24)};
    margin-bottom: ${rem(0)};
    border-bottom: none;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
  `}
`;

const MobileFiltersWrapper = styled(FlexRowCenterVertical)`
  display: none;
  justify-content: space-between;
  margin: 0 -${rem(16)};
  padding-bottom: ${rem(16)};
  position: relative;
  z-index: 1;
  border-bottom: ${rem(2)} solid #eeeeee;
  box-shadow: 0 ${rem(-16)} ${rem(1)} #fff, 0 ${rem(2)} ${rem(32)} #0000000a;

  ${({ theme }) => theme.mediab.l925`
      display: flex;
  `};
`;

const DesktopActiveFiltersWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.l1050`
      margin-bottom: ${rem(32)};
  `}

  ${({ theme }) => theme.mediab.m580`
    display: none;
  `}
`;

const MobileActiveFiltersWrapper = styled(FlexRowCenterVertical)`
  display: none;
  margin-top: ${rem(24)};

  ${({ theme }) => theme.mediab.m580`
    display: flex;
  `}
`;

const DesktopCheckboxesWrapper = styled.div`
  margin-left: auto;
  margin-right: ${rem(2)};

  ${({ theme }) => theme.mediab.l925`
   display: none;
  `};
`;

export const CategoryCardsContainer = styled.div<{ offset: number }>`
  padding-bottom: ${props => props.offset};
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(328px, 1fr));
  grid-gap: ${rem(32)};
  grid-row-gap: ${rem(16)};

  ${({ theme }) => theme.mediab.l1400`
    grid-gap: ${rem(16)};
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  `}

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}

  ${({ theme }) => theme.mediab.m580`
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  `}

  ${({ theme }) => theme.mediab.s450`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    display: none;
  `}
`;

const ListWrapper = styled.div`
  ${({ theme }) => theme.mediab.l925`

  `}

  ${({ theme }) => theme.mediab.m580`
    margin-top: ${rem(24)};
  `}
`;

const buttonLikeCss = css`
  font-size: ${rem(16)};
  font-weight: 600;
  line-height: ${rem(19)};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(4)};
  background: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.primary};
  margin: 0 auto;
`;

const StyledButton = styled.button`
  ${buttonLikeCss};
  padding: ${rem(14)} ${rem(16)} ${rem(15)};
  border: none;
  box-shadow: none;
  margin-top: ${rem(56)};
  max-width: ${rem(288)};
  width: 100%;
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.primaryOnHover};
    outline: none;
  }
`;

const MobileCheckboxesWrapper = styled.div`
  border-bottom: ${rem(2)} solid #eeeeee;
  padding: ${rem(32)} 0;
`;

const CategoryNameBagde = styled.p`
  ${buttonLikeCss};
  padding: ${rem(18)} ${rem(24)} ${rem(19)};
  margin-bottom: ${rem(8)};
`;

const CompanyInfoWrapper = styled.aside`
  margin-top: ${rem(56)};
  padding: 0 ${rem(24)};
`;

const CompanyInfoElement = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${rem(14)};
  font-weight: 500;
  line-height: ${rem(21)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  margin-top: 0;
  margin-bottom: ${rem(32)};
`;

const StyledSvgIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.primary};
`;

export default withRouter(Category);
